<template>
    <div class="py-5">
        <div class="sm:block md:flex block container">
            <div class="block w-full sm:p-0 md:pr-5 p-0 sm:text-center md:text-left text-center">
                <span class="text-2xl font-bold block">{{ data.Title }}</span>
                <p>{{ data.Description }}</p>
                <all-field :data="data.Form" />
                <div class="mt-2">
                    <b-button type="is-danger">SEND MESSAGE</b-button>
                </div>
            </div>
            <div class="w-full">
                <div class="map w-full" id="map" style="z-index: 0;height: 300px !important"></div>
                <!-- <img src="https://www.xda-developers.com/files/2019/06/google-maps-india.jpg" alt=""> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {
        AllField: () => import('../form/AllField')
    },
    props: {
        data: {
            type: Object
        }
    },
    mounted () {
        var data = {
            Latitude: 1.1628328180832364,
            Longitude: 104.04505209595521,
            Name: 'Elite Center'
        }
        this.leafletMap(data)
    }
}
</script>
